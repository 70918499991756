<template>
  <el-dialog
    id="ImportRestrictsDialog"
    ref="dialogWrapper"
    :title="title"
    :visible.sync="dialogShow"
    modal
    center
    :close-on-click-modal="false"
    width="95%"
    top="5vh"
    close-on-press-escape
    class="my-dialog abow_dialog"
    append-to-body
    @open="onOpen"
    @opened="onOpened"
    @close="onClose"
    @closed="onClosed"
    @keydown.enter.native="onFormEnter"
  >
    <el-header class="header2" height="48"
      ><el-row type="flex" justify="start" align="center">
        <TIButton icon="plus_green" @onClick="onSelectResponsed" :label="$t('Виділити усі розпізнані')" />
        <TIButton icon="plus_green" @onClick="onSelectAll" :label="$t('Виділити усі')" />
        <TIButton icon="plus_green" @onClick="onUnselectAll" :label="$t('Зняти виділення')" />
        <TIButton icon="delete_red" @onClick="onDeleteRestrict" :disabled="!selectedRows1" :label="$t('Видалити виділені умови')" />
        <TSeparator />
        <TIButton icon="plus_green" @onClick="onSelectResponsedSKU" :disabled="!selectedRows1" :label="$t('Виділити усі розпізнані товари')" />
        <TIButton icon="plus_green" @onClick="onSelectAllSKU" :disabled="!selectedRows1" :label="$t('Виділити усі товари')" />
        <TIButton icon="plus_green" @onClick="onUnselectAllSKU" :disabled="!selectedRows1" :label="$t('Зняти виділення товарів')" />
        <TSeparator />
        <TIButton icon="delete_red" @onClick="onDeleteSKU" :disabled="!selectedRows1 || !selectedRows2" :label="$t('Видалити виділені товари')" />
        <TSeparator />
      </el-row>
    </el-header>
    <el-container v-loading="loading" :element-loading-text="loadingText" style="padding-top:16px;">
      <div ref="flex_container" class="flex_container">
        <splitpanes class="default-theme" vertical style="height: 100%">
          <pane min-size="20" max-size="80" ref="pane1" size="35">
            <ag-grid-vue
              ref="RestrictTable1"
              style="width: 100%; height: 100%;"
              class="ag-theme-alpine"
              rowSelection="multiple"
              :defaultColDef="defaultColDef"
              :columnDefs="columns1"
              :headerHeight="32"
              :rowData="restricts"
              :gridOptions="gridOptions1"
              :frameworkComponents="frameworkComponents1"
              @selection-changed="onGridSelectionChanged1"
              :overlayNoRowsTemplate="noRowsToShowTemplate"
              @sortChanged="onGridColumnsChange1"
              @columnResized="onGridColumnsChange1"
              @columnMoved="onGridColumnsChange1"
              @displayedColumnsChanged="onGridColumnsChange1"
              @gridReady="onGridReady1"
              :modules="modules"
              :localeTextFunc="gridLocale"
              :enableBrowserTooltips="true"
            >
            </ag-grid-vue>
          </pane>
          <pane min-size="20" max-size="80" ref="pane2" size="65">
            <ag-grid-vue
              ref="RestrictTable2"
              style="width: 100%; height: 100%;"
              class="ag-theme-alpine"
              rowSelection="multiple"
              :defaultColDef="defaultColDef"
              :columnDefs="columns2"
              :headerHeight="32"
              :rowData="sku"
              :gridOptions="gridOptions2"
              :frameworkComponents="frameworkComponents2"
              @selection-changed="onGridSelectionChanged2"
              :overlayNoRowsTemplate="noRowsToShowTemplate"
              @sortChanged="onGridColumnsChange2"
              @columnResized="onGridColumnsChange2"
              @columnMoved="onGridColumnsChange2"
              @displayedColumnsChanged="onGridColumnsChange2"
              @gridReady="onGridReady2"
              :modules="modules"
              :localeTextFunc="gridLocale"
            >
            </ag-grid-vue>
          </pane>
        </splitpanes>
      </div>
    </el-container>
    <span slot="footer" class="dialog-footer">
      <el-button native-type="submit" type="success" @click="onCommit" class="apply-button" :disabled="!selectedRows1 || !selectedRows1.length">{{
        $t("Застосувати")
      }}</el-button>
      <el-button type="text" @click="dialogShow = false" class="cancel-button">{{ $t("Відмінити") }}</el-button>
    </span>
    <SKUInfo bus-event="restricts.import.sku.forms.info" />
  </el-dialog>
</template>

<script>
import { bus } from "@/main";
import { _ } from "vue-underscore";
import { AgGridVue } from "@ag-grid-community/vue";
import { AllModules } from "@ag-grid-enterprise/all-modules";
import { Splitpanes, Pane } from "splitpanes";
import "splitpanes/dist/splitpanes.css";
import Icon from "@/components/IconPack";
import TIButton from "@/components/ToolbarIconButton";
import TSeparator from "@/components/ToolbarSeparator";
import GridCellWithIcons from "@/components/GridCellWithIcons.js";
import GridTotalBottomRow from "@/components/GridTotalBottomRow.js";

function parseError(error) {
  if (typeof error == "string") {
    return error;
  } else if (Array.isArray(error)) {
    return error.join("; ");
  } else return error;
}

export default {
  name: "ImportRestricts",
  components: { AgGridVue, Splitpanes, Pane, Icon, TIButton, TSeparator, SKUInfo: () => import("@/components/forms/SKUInfo") },
  data() {
    var that = this;
    return {
      modules: AllModules,
      dialogShow: false,
      dialogHeight: 0,
      resizeObserver: null,
      title: "Пошук",
      target: null,
      filterString: "",
      skuExists: [],
      noRowsToShowTemplate: `<div><img src="${require("../../../assets/icons/no_data.svg")}"></div>`,
      selectedRows1: null,
      selectedRows2: null,
      keyUpTimer: null,
      gridApi1: null,
      gridApi2: null,
      operation: "",
      gridColumnApi1: null,
      gridColumnApi2: null,
      initModel: null,
      model: null,
      frameworkComponents1: null,
      frameworkComponents2: null,
      gridOptions1: {
        suppressCellSelection: true,
        getRowNodeId: function(data) {
          return data.restrictId;
        },
        immutableData: true,
        getRowStyle: function(params) {
          if (that.restrictExists.findIndex((item) => item.name == params.data.restrictName) > -1) return { "font-weight": "bold" };
          else return null;
        },
        rowHeight: 32,
        statusBar: {
          statusPanels: [
            {
              statusPanel: "agTotalAndFilteredRowCountComponent",
              align: "left",
            },
            {
              statusPanel: "GridTotalBottomRow",
              align: "left",
              items: [
                {
                  id: 1,
                  label: $tt("Нові умови"),
                  value: () => {
                    if (that.newResricts) return that.newResricts.length;
                    else return 0;
                  },
                },
              ],
            },
          ],
        },
      },
      gridOptions2: {
        suppressCellSelection: true,
        getRowNodeId: function(data) {
          return data.importId;
        },
        immutableData: true,
        rowHeight: 32,
        floatingFiltersHeight: 32,
        statusBar: {
          statusPanels: [
            {
              statusPanel: "agTotalAndFilteredRowCountComponent",
              align: "left",
            },
          ],
        },
      },
      defaultColDef: {
        suppressNavigable: true,
        cellClass: "no-border",
        resizable: true,
        sortable: true,
        tooltipValueGetter: function(params) {
          return params.value;
        },
        floatingFilter: this.$store.getters["main/userSettings"]("userSettings").floatingFilter == "true",
        suppressMenu: false,
        filter: "agTextColumnFilter",
        floatingFilterComponentParams: { suppressFilterButton: true },
      },
      columns1: [
        {
          headerName: "Назва умови",
          field: "restrictName",
          headerCheckboxSelection: true,
          checkboxSelection: true,
          headerCheckboxSelectionFilteredOnly: true,
          cellRenderer: "GridCellWithIcons",
          cellIcons: [
            {
              icon: "trash",
              iconClass: "icon-action",
              type: "fas el-icon-fa-",
              color: "red",
              title: "Видалити умову",
              onClick: (params) => {
                this.onDeleteRestrict(null, params);
              },
            },
          ],
        },
        {
          headerName: "Тип умови",
          field: "restrictType",
          width: "100px",
          valueGetter: function(params) {
            return that.selectOptions.restricts.type.find((option) => option.value == params.data.restrictType).label;
          },
        },
        {
          headerName: "План умови",
          field: "restrictPlan",
          width: "100px",
        },
        {
          headerName: "Бонус умови",
          field: "restrictBonus",
          width: "100px",
        },
        {
          headerName: "Товари розпізнано/всього",
          field: "SKUCount",
          width: "100px",
          valueGetter(value) {
            return value.data.sku ? value.data.sku.filter((sku) => sku.drugsId).length + "/" + value.data.sku.length : "0/0";
          },
        },
      ].map((column) => _.extend(column, { headerTooltip: column.headerName })),
      columns2: [
        {
          headerName: "Morion ID",
          field: "drugsId",
          width: "200px",
          headerCheckboxSelection: true,
          checkboxSelection: true,
          headerCheckboxSelectionFilteredOnly: true,

          cellRenderer: "GridCellWithIcons",
          cellIcons: [
            {
              icon: "trash",
              iconClass: "icon-action",
              type: "fas el-icon-fa-",
              color: "red",
              title: "Видалити товар",
              onClick: (params) => {
                this.onDeleteSKU(null, params);
              },
            },
            {
              icon: "info-circle",
              type: "fas el-icon-fa-",
              color: "blue",
              iconClass: "icon-action",
              title: "Інформація",
              show: function(params) {
                return params.data.drugsId;
              },
              onClick: (params) => {
                this.showInfo(null, params.data);
              },
            },
          ],
        },
        {
          headerName: "Назва умови",
          field: "restrictName",
          flex: 1,
        },
        {
          headerName: "Похідна назва",
          field: "srcName",
          flex: 2,
        },
        {
          headerName: "Похідна Morion ID",
          field: "srcDrugsId",
          flex: 2,
        },
        {
          headerName: $tt("Назва"),
          field: "sku",
          flex: 2,
        },
        {
          headerName: $tt("Виробник"),
          field: "maker",
          flex: 1,
        },
        {
          headerName: $tt("Маркетуюча організація"),
          field: "marketOrg",
          flex: 1,
        },
        {
          headerName: $tt("Бренд"),
          field: "brand",
          flex: 1,
        },
        {
          headerName: $tt("Рег. дата початок"),
          field: "regDateBegin",
          width: "200px",
        },
        {
          headerName: $tt("Рег. дата завершення"),
          field: "regDateEnd",
          width: "200px",
        },
        {
          headerName: $tt("Форма"),
          field: "drugForm",
          flex: 1,
        },
        {
          headerName: $tt("Номер"),
          field: "drugFormNumber",
          width: "100px",
        },
        {
          headerName: $tt("CIP Ціна"),
          field: "cip",
          width: "100px",
        },
      ].map((column) => _.extend(column, { headerTooltip: column.headerName })),
    };
  },
  mounted() {},

  created() {
    bus.$on("forms.restrict.import.show", (data) => {
      if (!this.dialogShow) {
        this.title = (data && data.title) || this.title;
        this.initModel = (data && data.initModel) || {};
        this.filterString = "";
        this.dialogShow = true;
      }
    });
    bus.$on("forms.restrict.import.hide", () => {
      this.dialogShow = false;
      this.resizeObserver = null;
    });
    bus.$on("forms.restrict.import.xls.clear.clear", () => {
      this.fileList = [];
    });
  },
  beforeMount() {
    this.frameworkComponents1 = { GridCellWithIcons, GridTotalBottomRow };
    this.frameworkComponents2 = { GridCellWithIcons };
  },
  computed: {
    restricts() {
      return this.$store.getters["restricts/importStore"]();
    },
    loading() {
      return this.$store.getters["restricts/wait"]();
    },
    loadingText() {
      return this.$store.getters["restricts/waitStatus"]();
    },
    sku() {
      if (this.selectedRows1) {
        var sku = [];
        this.selectedRows1.forEach((restrict) => {
          sku = sku.concat(restrict.sku);
        });
        return sku;
      } else return [];
    },
    restrictExists() {
      if (this.model && this.model.restrictExists) {
        return this.model.restrictExists;
      } else return [];
    },
    newResricts() {
      if (this.model && this.model.restrictExists) {
        var newNames = this.$store.getters["restricts/importStore"]()
          .filter((item) => item.responsedSKUCount)
          .map((item) => item.restrictName);
        return this.model.restrictExists.filter((item) => newNames.indexOf(item.name) == -1);
      } else return this.$store.getters["restricts/importStore"]();
    },
    selectOptions() {
      return this.$store.getters["main/selectionOptions"];
    },
  },
  watch: {
    dialogHeight(value) {
      var containerHeight = value - 48 - 72 - 64;
      this.$refs.flex_container.style.height = `${containerHeight}px`;
    },
  },
  methods: {
    onGridReady1() {
      this.gridApi1 = this.gridOptions1.api;
      this.gridColumnApi1 = this.gridOptions1.columnApi;
      var state = this.$store.getters["main/userSettings"]("ImportRestrictsGridColumnState1");
      if (state) this.gridColumnApi1.setColumnState(state);
    },
    onGridReady2() {
      this.gridApi2 = this.gridOptions2.api;
      this.gridColumnApi2 = this.gridOptions2.columnApi;
      var state = this.$store.getters["main/userSettings"]("ImportRestrictsGridColumnState2");
      if (state) this.gridColumnApi2.setColumnState(state);
    },
    observeHeight() {
      var that = this;
      if (!this.resizeObserver) {
        this.resizeObserver = new ResizeObserver(function() {
          if (that.$refs.dialogWrapper && that.$refs.dialogWrapper.$el) that.dialogHeight = that.$refs.dialogWrapper.$el.children[0].clientHeight;
        });
        this.resizeObserver.observe(this.$el.children[0]);
      }
    },
    unobserveHeight() {
      this.resizeObserver && this.resizeObserver.unobserve(this.$el.children[0]);
      this.resizeObserver = null;
      this.dialogHeight = 0;
    },
    resetFilter(event) {
      if (this.filterString != "") {
        event && event.stopPropagation();
        if (this.loading) {
          this.$store.dispatch("main/cancelAllRequests");
        } else this.$refs.filter.clear();
      }
    },
    onOpen() {
      this.observeHeight();
    },
    onOpened() {
      this.model = this.initModel || {};
    },
    onClose() {
      this.$store.dispatch("main/cancelAllRequests");
      this.dialogShow = false;
    },
    onClosed() {
      this.unobserveHeight();
    },
    onCommit() {
      if (this.selectedRows1 && this.selectedRows1.length > 0) {
        this.dialogShow = false;
        this.$emit("import-commit", { data: this.selectedRows1 });
      } else this.$message("Будь ласка, оберіть один або декілька рядків в лівій таблиці");
    },
    onGridSelectionChanged1() {
      var rows = this.gridOptions1.api.getSelectedRows();
      if (rows && rows.length > 0) {
        this.selectedRows1 = rows;
      } else {
        this.selectedRows1 = null;
      }
    },
    onGridSelectionChanged2() {
      var rows = this.gridOptions2.api.getSelectedRows();
      if (rows && rows.length > 0) {
        this.selectedRows2 = rows;
      } else {
        this.selectedRows2 = null;
      }
    },
    onFormEnter() {
      this.onAdd();
    },
    onGridColumnsChange1() {
      var that = this;
      if (this.changeColumnsTimer) clearTimeout(this.changeColumnsTimer);
      this.changeColumnsTimer = setTimeout(() => {
        var state = that.gridColumnApi1.getColumnState();
        clearTimeout(that.changeColumnsTimer);
        that.$store.dispatch("main/updateUserSettings", { ImportRestrictsGridColumnState1: state });
      }, 500);
    },
    onGridColumnsChange2() {
      var that = this;
      if (this.changeColumnsTimer) clearTimeout(this.changeColumnsTimer);
      this.changeColumnsTimer = setTimeout(() => {
        var state = that.gridColumnApi2.getColumnState();
        clearTimeout(that.changeColumnsTimer);
        that.$store.dispatch("main/updateUserSettings", { ImportRestrictsGridColumnState2: state });
      }, 500);
    },
    gridLocale(key, defaultValue) {
      var localized = $tt("grid." + key);
      return localized ? localized : defaultValue;
    },
    onSelectResponsed() {
      this.gridApi1.deselectAll();
      this.gridOptions1.api.forEachNodeAfterFilter((node) => {
        if (node.data.responsedSKUCount) node.setSelected(true);
      });
    },
    onSelectResponsedSKU() {
      this.gridApi2.deselectAll();
      this.gridOptions2.api.forEachNodeAfterFilter((node) => {
        if (node.data.drugsId) node.setSelected(true);
      });
    },
    onSelectAll() {
      this.gridApi1.selectAll();
    },
    onUnselectAll() {
      this.gridApi1.deselectAll();
    },
    updateNodeSelectionStatus(node) {
      if (node.isSelected && !node.data.responsedSKUCount) {
        node.setSelected(false);
      }
    },
    onSelectAllSKU() {
      this.gridApi2.selectAll();
    },
    onUnselectAllSKU() {
      this.gridApi2.deselectAll();
    },
    onDeleteSKU(event, params) {
      if (params) {
        var node = this.gridOptions1.api.getRowNode(params.data.restrictId);
        this.$store.dispatch("restricts/deleteFromImportSKU", [params.data.importId]);
        //        this.updateNodeSelectionStatus(node);
        this.gridOptions1.api.refreshCells({ rowNodes: [node], force: true });
      } else if (this.selectedRows2 && this.selectedRows2.length > 0) {
        var delIds = this.selectedRows2.map((item) => item.importId);
        var nodes = this.selectedRows2.map((item) => this.gridOptions1.api.getRowNode(item.restrictId));
        this.$store.dispatch("restricts/deleteFromImportSKU", delIds);
        //        nodes.forEach((node) => this.updateNodeSelectionStatus(node));
        this.gridOptions1.api.refreshCells({ rowNodes: nodes, force: true });
      }
    },
    onDeleteRestrict(event, params) {
      if (params) {
        this.$store.dispatch("restricts/deleteFromImport", [params.data.restrictId]);
      } else if (this.selectedRows1 && this.selectedRows1.length > 0) {
        var delIds = this.selectedRows1.map((item) => item.restrictId);
        this.$store.dispatch("restricts/deleteFromImport", delIds);
      }
    },
    onFindRestrictCommit(data) {
      if (data.result && data.result.length > 0) {
        var existIds = this.restrict.sku.map((item) => item.drugsId);
        var newSKU = data.result.filter((item) => existIds.indexOf(item.drugsId) == -1);
        var newData = this.restrict.sku.concat(newSKU);
        this.$store
          .dispatch("restricts/insertSKU", {
            contractId: this.$store.getters["contracts/contract"]()._id,
            restrictId: this.$store.getters["restricts/restrict"]()._id,
            data: newData,
          })
          .then(() => {
            this.$message.success("Товари додано!");
          })
          .catch((err) => {
            globalErrorMessage("Помилка збереження!", err);
          });
      }
    },
    showInfo(event, data) {
      if (data) {
        bus.$emit("restricts.import.sku.forms.info.show", {
          title: $tt("Інформація про товар"),
          initModel: { drugsId: data.drugsId },
        });
      }
    },
  },
};
</script>

<style lang="scss" scope>
#ImportRestrictsDialog .header2 {
  background: #fff;
  line-height: 32px;
  border-bottom: 1px solid #ccc !important;
  padding: 0 !important;
  align-items: center;
  margin-left: -16px;
  margin-right: -16px;
}

#ImportRestrictsDialog.my-dialog .el-dialog--center .el-dialog__body {
  padding: 0px 16px 0 16px !important;
}
</style>
